import firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

const firebaseConfig = {
    apiKey: "AIzaSyDZ7a-Qt35y4ztc_5_g7uqJu4iTyZJtRuc",
    authDomain: "remote-noise-monitoring.firebaseapp.com",
    databaseURL: "https://remote-noise-monitoring.firebaseio.com",
    projectId: "remote-noise-monitoring",
    storageBucket: "remote-noise-monitoring.appspot.com",
    messagingSenderId: "411045245265",
    appId: "1:411045245265:web:e0be9141e11d3a7cf9e370"
  };

  firebase.initializeApp(firebaseConfig);

  export const fb = firebase;

  export const auth = firebase.auth();

  export const db = firebase.firestore();

  export const firestore = firebase.firestore;

  export const functions = firebase.functions();