import React from 'react';

import Graph from '../Graph/Graph';

const GraphsContainer = (props) => {

    const meters = props.meters

    return (
        <div>
            {meters.map(meter => {
                return <Graph meter = {meter} key = {meter} />
            })}
        </div>
    )
}

export default GraphsContainer;