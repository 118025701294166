const calcLaeq = async (readings, startMins) => {

    // console.log(`Calculating average for ${startMins} mins`);

    if (readings.length < 2) {
        return null;
    }

    const lastReading = readings[readings.length-1];

    const thisTimestamp = lastReading.humanTimestamp;

    const startLimit = new Date(thisTimestamp.getTime() - (startMins * 60 * 1000));
    const upperStart = new Date(startLimit.getTime() + 10000);
    const lowerStart = new Date(startLimit.getTime() - 10000);

    const chronoReadings = readings.filter((reading) => reading.humanTimestamp > lowerStart);

    //const firstReading = chronoReadings[0][1];
    const firstReading = chronoReadings[0];

    console.log('firstReading', firstReading.humanTimestamp);
    console.log('lastReading', lastReading.humanTimestamp);
    console.log('startLimit', startLimit);

    

    if (!firstReading.timestamp) {
        return null;
    }

    console.log(`${startMins} mins: Will compare ${firstReading.timestamp.toDate()} with ${startLimit}`)


    if (!((firstReading.timestamp.toDate()  >= lowerStart) && (firstReading.timestamp.toDate() <= upperStart))) {
        console.log(`${startMins} mins: Time is NOT within acceptable margin. Difference is: ${Math.abs((startLimit - firstReading.timestamp.toDate())/1000)}`)
        return null;
    }


    console.log(`${startMins} mins: Time is within acceptable margin. Difference is: ${Math.abs((startLimit - firstReading.timestamp.toDate())/1000)}`)


    // Everything is pretty legal. We can now continue and average it...



    const samples = chronoReadings.map(reading => {
        return parseFloat(reading.laeq);
    })

    let sum = 0.0;

    samples.forEach(value => {
        sum += Math.pow(10, value/10.0);
    })

    const avg = sum / samples.length;

    const logAvg = 10 * Math.log10(avg);

    console.log(`${startMins} mins: laeq(${startMins}) is ${logAvg}`);

    return logAvg;
 
}

export default calcLaeq;