import React, { useEffect, useState } from 'react';

import Moment from 'moment';

import { db, firestore } from '../../services/firebase';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import calcAverage from './calcAverage';

import styles from './graph.module.css';

const Graph = (props) => {
    const id = props.meter;
    const meterRef = db.collection('monitors').doc(id);

    const [meterMeta, setMeterMeta] = useState({});
    const [readings, setReadings] = useState([]);
    const [filteredReadings30, setFilteredReadings30] = useState([]);
    const [filteredReadingsT, setFilteredReadingsT] = useState([]);
    const [numLeqT, setNumLeqT] = useState(localStorage.getItem(`meter-${id}-t`) || 0);
    const [leq30, setLeq30] = useState(0.0);
    const [leqT, setLeqT] = useState(0.0);
    const [lastReading, setLastReading] = useState({});
    const [instBands, setInstBands] = useState([0,0,0,0,0,0,0,0,0,0,0,0]);
    const [chartData, setChartData] = useState({});





    const freqBands = ['8Hz','16Hz','31.5Hz','63Hz','125Hz','250Hz','500Hz','1KHz','2KHz','4KHz','8KHz','16KHz'];


    useEffect(() => {
        meterRef.get().then(result => {
            setMeterMeta(result.data());
        }).catch(err => console.log('Error reading meter meta, ', err))
        meterRef.collection('readings').orderBy('timestamp', 'desc').limit(300).onSnapshot(snapshot => {
            const snapshotReadings = [];

            snapshot.forEach(doc => {
                snapshotReadings.push([doc.id, doc.data()])
            })

            setReadings(snapshotReadings);            
        })

    },[])

    useEffect(() => {
        const dbReadings = [...readings];
        const readings30 = [];
        const readingsT = [];

        const startLimit30 = new Date(new Date().getTime() - (30*60*1000) - 4000);
        const startLimitT = new Date(new Date().getTime() - (numLeqT * 60 * 1000) - 4000);

        // Do 30min readings
        dbReadings.forEach(reading => {
            if (reading[1].timestamp.toDate() >= startLimit30 ) {
                readings30.push(reading);
            }
        })

        // Do Tmin readings
        dbReadings.forEach(reading => {
            if (reading[1].timestamp.toDate() >= startLimitT ) {
                readingsT.push(reading);
            }
        })

        // Save most recent readings
        const last = dbReadings[0];
      
        if (last) {
            setLastReading(last[1]);
            console.log(`The last reading was ${last[1].timestamp.toDate().toString()}`)
        }
        setFilteredReadings30(readings30);
        setFilteredReadingsT(readingsT);
    }, [readings, numLeqT])

    useEffect(() => {
        const laeq30 = calcAverage(filteredReadings30, 30) || 0;
        setLeq30(laeq30);
    }, [filteredReadings30])

    useEffect(() => {
        const laeqT = calcAverage(filteredReadingsT, numLeqT) || 0;
        setLeqT(laeqT);
    }, [filteredReadingsT, numLeqT])

    useEffect(() => {
        const duplicate = {
            '8Hz':      Number(lastReading['8hz']) || 0,
            '16Hz':     Number(lastReading['16hz']) || 0,
            '31.5Hz':   Number(lastReading['31.5hz']) || 0,
            '63Hz':     Number(lastReading['63hz']) || 0,
            '125Hz':    Number(lastReading['125hz']) || 0,
            '250Hz':    Number(lastReading['250hz']) || 0,
            '500Hz':    Number(lastReading['500hz']) || 0,
            '1KHz':     Number(lastReading['1khz']) || 0,
            '2KHz':     Number(lastReading['2khz']) || 0,
            '4KHz':     Number(lastReading['4khz']) || 0,
            '8KHz':     Number(lastReading['8khz']) || 0,
            '16KHz':    Number(lastReading['16khz']) || 0,            
        }

        console.log('instbands ', Object.values(duplicate))
        setInstBands(Object.values(duplicate));


    }, [lastReading])

    useEffect(() => {
        const chartOptions = {
            chart: {
                type: 'column',
                // marginLeft: 0,
                // marginRight: 0,
                // width: 400,

            },
            xAxis: {
              categories: [...freqBands],
            },
            yAxis: {
                min: 1,
                max: 100,
                tickPositions: [15,20,30,40,50,60,70,80,90].map((x) => (x)),
                type: 'linear'
            },
            series: [{ 
                  data: [0,0,0,0,0,0,0,0,0,0,0,0] ,
                  name: 'Data Unavailable'
            }],
            plotOptions: {
                column: {
                    groupPadding: 0
                }
            },
            credits: {
                enabled: false
            },
            title: {
                text: ''
            }
          }

        if (lastReading && lastReading.timestamp && (new Date().getTime() - lastReading.timestamp.toDate().getTime() < 900000)) {
            chartOptions.series = [{ 
                data: [...instBands] ,
                name: 'LAeq(10 seconds)'
          }]
        }

        setChartData(chartOptions);
    }, [instBands])


    const handleChangeT = (value) => {
        setNumLeqT(value)
        localStorage.setItem(`meter-${id}-t`, value)
    }

    let els;


    if (filteredReadings30) {
        els = filteredReadings30.map(entry => {
            return (
                <li key={entry[0]}>{entry[1].timestamp.toDate().toString()}</li>
            )
        })
    }

    const momentTimestamp = lastReading && lastReading.timestamp ? Moment(lastReading.timestamp.toDate()) : null;



    
    return (
        <div className={styles['graph-container']}>
            <div className={styles.meterId}>Meter ID: {id}</div>
            <div><strong>{meterMeta.name || 'N/A'}</strong></div>
            <div>Updated at: {lastReading && lastReading.timestamp ? momentTimestamp.format('DD/MM/YY HH:mm:ss') : 'N/A'}</div>
            <div>
                <label htmlFor="t-input">Custom LAeq (minutes): </label>
                <input id={'t-input'} name={'t-input'} className={styles.tInput} type="number" value={numLeqT} onChange={e => handleChangeT(e.target.value)}/>
            </div>
            <div>
                <HighchartsReact highcharts={Highcharts} options={chartData}/>
            </div>
            <div>
                <div className={styles.soundValue}>LAeq(30m): <strong>{leq30 ? leq30.toFixed(1) : 'N/A'}</strong></div>
                <div className={styles.soundValue}>LAeq({numLeqT}m): <strong>{leqT ? leqT.toFixed(1) : 'N/A'}</strong></div>
            </div>
        </div>
    )
}


export default Graph;