import React, { useState } from 'react';

import firebase from 'firebase/app';

import { ExportToCsv } from 'export-to-csv';

import { db } from '../../services/firebase';

import calcLaeq from './calcLaeq';

const Download = (props) => {

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [monitorId, setMonitorId] = useState('');

    const monitorsRef = db.collection('monitors');

    const meters = props.meters;

    const LaeqMins = 30;

    const downloadData = () => {

        const startDateObj = new Date(startDate);
        const endDateObj = new Date(endDate);

        let name = '';

        monitorsRef.doc(monitorId).get().then(doc => {
 
            console.log(doc.data())
            name = doc.data().name;

        })

        monitorsRef.doc(monitorId).collection('readings').where('timestamp', '>', startDateObj).where('timestamp', '<', endDateObj).get().then(async snapshot => {
            if (!snapshot.empty) {
                let readings = [];

                snapshot.forEach(entry => {
                    const humanTimestamp = entry.data().timestamp.toDate();
                    const epochTImestamp = humanTimestamp.getTime();
                    readings.push({...entry.data(), humanTimestamp, epochTImestamp})
                })

                console.log(readings)

                /*
                readings.forEach(async (entry, i, arr) => {
                    console.log(`Is there a Laeq${LaeqMins} available for ${entry.humanTimestamp}? i ${i}`)
                    // 30 mins of data should be 180 readings. Skip if fewer.
                    if (i < 179) { 
                        console.log(`${entry.humanTimestamp} is less than 180 entries. Skipping.`)
                        return;
                    }

                    const prevData = arr.slice(0, i+1);
                    const laeq = await calcLaeq(prevData, LaeqMins);

                    if (laeq) {
                        console.log(`Laeq for ${entry.humanTimestamp} is ${laeq}`)
                        readings[i].laeq = laeq;
                    }


                })
                */

                for (let i=0; i < readings.length; i++) {
                    const entry = readings[i];

                    console.log(`Is there a Laeq${LaeqMins} available for ${entry.humanTimestamp}? i ${i}`);
                    // 30 mins of data should be 180 readings. Skip if fewer.
                    if (i < 179) { 
                        console.log(`${entry.humanTimestamp} is less than 180 entries. Skipping.`);
                        readings[i].laeq30 = 0;
                    } else {
                        const prevData = readings.slice(0, i+1);
                        const laeq = await calcLaeq(prevData, LaeqMins);
    
                        if (laeq) {
                            console.log(`Laeq for ${entry.humanTimestamp} is ${laeq}`)
                            readings[i].laeq30 = laeq.toFixed(1);
                        } else {
                            readings[i].laeq30 = 0;
                        }
                    }


                }

                console.log(readings)



                // Generate CSV

                const d = new Date()

                const fileName = `Noise - ${d.getDate()}-${d.getMonth()}-${d.getFullYear()} - ${name}`

                const csvOptions = {
                    useKeysAsHeaders: true,
                    useBom: true,
                    title: 'Noise Data Download',
                    filename: fileName,
                }

                const csvExporter = new ExportToCsv(csvOptions);

                csvExporter.generateCsv(readings)
                
                
            }
        })
    }

    return (
        <div>
            <div>
                <label htmlFor="startDate">Start Date:</label>
                <input id="startDate" name="startDate" type="datetime-local" value={startDate} onChange={e => setStartDate(e.target.value)} />

                <label htmlFor="endDate">End Date:</label>
                <input id="endDate" name="endDate" type="datetime-local" value={endDate} onChange={e => setEndDate(e.target.value)} />

                <label htmlFor="monitorId">Monitor ID:</label>
                <input name="monitorId" id="monitorId" type="text" value={monitorId} onChange={e => setMonitorId(e.target.value)} />
                
                <button onClick={() => {downloadData()}}>Download</button>
            </div>

        </div>
    )
}


export default Download;