import React from 'react';

import styles from './Header.module.css';

const Header = (props) => {
    return (
        <header className={styles.header}>
            <span>Fluid Productions Noise Monitoring</span>
            {props.signOut ? <span className={styles.signout} onClick={() => props.signOut()}>Sign Out</span> : null}
        </header>
    )
}

export default Header;