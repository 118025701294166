import React, { useState, useEffect } from 'react';

import './App.css';

import firebase from 'firebase/app';

import { auth, db } from './services/firebase';


import Header from './components/Header/Header';
import GraphsContainer from './components/GraphsContainer/GraphsContainer';
import SignIn from './components/SignIn/SignIn';
import Download from './components/Download/Download';

function App() {

  const [user, setUser] = useState (null);
  // const [allowedMeters, setAllowedMeters] = useState([]);
  const [meters, setMeters] = useState([]);


  const usersRef = db.collection('users');
  const monitorsRef = db.collection('monitors');


  useEffect(() => {
    // Auth
    auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user)

      } else {
        setUser(null);
        // setAllowedMeters([]);

      }
    })


    monitorsRef.where('enabled', '==', true).onSnapshot(querySnapshot => {
      const snapshotEntries = [];
      querySnapshot.forEach(entry => {
        snapshotEntries.push(entry.data().IMEI);
      })
      setMeters(snapshotEntries);
    })

  },[])


  const signInUser = (email, password) => {
    auth.signInWithEmailAndPassword(email, password)
      .catch(err => {
        if (err.code == 'auth/user-not-found' || err.code == 'auth/wrong-password') {
          window.alert('Incorrect email/password');
        } else {
          console.error('Error signing in, ', err);
          window.alert('Error signing in. Please check the console');
        }
      })
  }

  const signOutUser = () => {
    auth.signOut()
  }




  return (
    <div className="App">
      {user ? <Header signOut={signOutUser} /> : <Header />}
      {user ? <GraphsContainer meters={meters}/> : <SignIn signInUser={signInUser}/>}

      {user ? <Download meters={meters}/> : <div>Not signed in</div>}

    </div>
  );
}

export default App;
